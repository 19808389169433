import { inStates, outStates } from "../modules/hooks";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { useAppState } from "./App";

function CategoryHeading({ category }) {
  const { state } = useAppState();
  const transitioningIn = inStates.includes(state);
  const transitioningOut = outStates.includes(state);
  return (
    <div className="category__heading-wrapper">
      <h1
        className={classNames({
          category__heading: true,
          "dip-transition-in": transitioningIn,
          "dip-transition-out": transitioningOut,
        })}
      >
        {category}
      </h1>
    </div>
  );
}

CategoryHeading.propTypes = {
  category: PropTypes.string.isRequired,
};

export default CategoryHeading;
