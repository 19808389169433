import CategoryHeading from "./CategoryHeading";
import CategoryNav from "./CategoryNav";
import NavSpacer from "./NavSpacer";
import Page from "./Page";
import ProjectGrid from "./ProjectGrid";
import PropTypes from "prop-types";
import React from "react";
import { coreDataType } from "../modules/utils";

function CategoryPage({ pageData, coreData }) {
  const category = pageData.title;

  return (
    <>
      <CategoryNav
        categoryPages={coreData.simpleCategoryPages}
        defaultCategory={category}
      />
      <Page coreData={coreData}>
        <NavSpacer />
        <CategoryHeading category={category} />
        <ProjectGrid projects={pageData.childPageData} category={category} />
      </Page>
    </>
  );
}

CategoryPage.propTypes = {
  pageData: PropTypes.shape({
    childPageData: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  coreData: coreDataType.isRequired,
};

export default CategoryPage;
