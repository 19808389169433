import FacebookSvg from "../../images/facebook.svg";
import InstagramSvg from "../../images/instagram.svg";
import PropTypes from "prop-types";
import React from "react";
import TwitterSvg from "../../images/twitter.svg";
import VimeoSvg from "../../images/vimeo.svg";

function SocialLinks({
  theme,
  vimeoUrl,
  twitterUrl,
  facebookUrl,
  instagramUrl,
}) {
  return (
    <div className={`social-links ${theme}`}>
      <a
        href={vimeoUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <VimeoSvg className="social-logo" />
      </a>
      <a
        href={twitterUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <TwitterSvg className="social-logo" />
      </a>
      <a
        href={facebookUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <FacebookSvg className="social-logo" />
      </a>
      <a
        href={instagramUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="social-link"
      >
        <InstagramSvg className="social-logo" />
      </a>
    </div>
  );
}

SocialLinks.propTypes = {
  theme: PropTypes.oneOf(["pink-light", "pink-dark", "white"]).isRequired,
  vimeoUrl: PropTypes.string.isRequired,
  twitterUrl: PropTypes.string.isRequired,
  facebookUrl: PropTypes.string.isRequired,
  instagramUrl: PropTypes.string.isRequired,
};

SocialLinks.defaultProps = {
  theme: "white",
};

export default SocialLinks;
