import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";

function CTA({ url, text, state }) {
  const history = useHistory();

  return (
    <a
      href={url}
      className="cta"
      onClick={(e) => {
        history.push(url, { state });
        e.preventDefault();
      }}
    >
      {text}
    </a>
  );
}

CTA.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  state: PropTypes.string,
};

export default CTA;
