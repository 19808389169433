import PropTypes from "prop-types";

export const simplePageType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

export const coreDataType = PropTypes.shape({
  contactSettings: PropTypes.shape({
    addressCompany: PropTypes.string.isRequired,
    addressStreet: PropTypes.string.isRequired,
    addressCity: PropTypes.string.isRequired,
    addressPostcode: PropTypes.string.isRequired,
  }),
  socialSettings: PropTypes.shape({
    vimeoUrl: PropTypes.string.isRequired,
    twitterUrl: PropTypes.string.isRequired,
    facebookUrl: PropTypes.string.isRequired,
    instagramUrl: PropTypes.string.isRequired,
  }),
  simpleCategoryPages: PropTypes.arrayOf(simplePageType).isRequired,
  simpleAboutPage: simplePageType.isRequired,
  simpleContactPage: simplePageType.isRequired,
});

export function scrollIntoView(el) {
  el.scrollIntoView({
    behavior: "smooth",
  });
}
