import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";
import { mapStyles } from "../modules/mapStyles";

function loadJS(src) {
  const firstScriptTag = window.document.getElementsByTagName("script")[0];
  const tag = window.document.createElement("script");
  tag.src = src;
  tag.async = true;
  tag.defer = true;
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function Map({ lat, lng }) {
  const mapElRef = useRef(null);

  useEffect(() => {
    let map;
    let latlng;

    function handleResize() {
      if (map && latlng) {
        window.google.maps.event.trigger(map, "resize");
        map.setCenter(latlng);
      }
    }

    function handleGoogleAPIReady() {
      latlng = new window.google.maps.LatLng(lat, lng);

      map = new window.google.maps.Map(mapElRef.current, {
        zoom: 17,
        center: latlng,
        scrollwheel: false,
        disableDefaultUI: true,
        draggable: !("ontouchstart" in document.documentElement),
        styles: mapStyles,
      });

      // const icon = {
      //   url: "/static/images/pin.svg",
      //   size: new window.google.maps.Size(50, 65),
      //   scaledSize: new window.google.maps.Size(30, 42),
      //   anchor: new window.google.maps.Point(15, 35),
      // };

      const marker = new window.google.maps.Marker({
        position: latlng,
        // icon: icon,
      });

      marker.setMap(map);
    }

    const url = "https://maps.googleapis.com/maps/api/js";
    const key = "AIzaSyC07eADSlb2Ik-Mi5xLewYmNgJpR0X3pA0";
    window.handleGoogleAPIReady = handleGoogleAPIReady;
    loadJS(url + "?key=" + key + "&callback=handleGoogleAPIReady");
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <div className="map" ref={mapElRef} />;
}

Map.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
};

export default Map;
