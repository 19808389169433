import BurgerButton from "./BurgerButton";
import React from "react";
import classNames from "classnames";
import { useAppState } from "./App";
import { useHistory } from "react-router";

function MainNav() {
  const { menuOpen, setMenuOpen, navCollapsed, setNavCollapsed } =
    useAppState();

  const history = useHistory();

  return (
    <div
      onMouseOver={() => {
        setNavCollapsed(false);
      }}
      className={classNames({
        "main-nav": true,
        "menu-open": menuOpen,
        collapsed: navCollapsed,
      })}
    >
      <div className="main-nav__inner">
        <a
          href="/"
          className="main-nav__logo"
          onClick={(e) => {
            e.preventDefault();
            history.push("/");
          }}
        >
          Giant Owl
        </a>
        <BurgerButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      </div>
    </div>
  );
}

export default MainNav;
