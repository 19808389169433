import { inStates, outStates } from "../modules/hooks";
import { useHistory, useLocation } from "react-router";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { useAppState } from "./App";

function CategoryNav({ categoryPages, defaultCategory }) {
  const { navCollapsed, setNavCollapsed, state, targetPathname, prevPathname } =
    useAppState();
  const history = useHistory();
  const location = useLocation();

  const enteringPortfolio = !prevPathname.startsWith("/portfolio");
  const exitingPortfolio = !targetPathname.startsWith("/portfolio");
  const transitioningIn = inStates.includes(state);
  const transitioningOut = outStates.includes(state);

  return (
    <div
      className={classNames({
        "category-nav": true,
        collapsed: navCollapsed,
        "fade-transition-in": transitioningIn && enteringPortfolio,
        "fade-transition-out": transitioningOut && exitingPortfolio,
      })}
      onMouseOver={() => {
        setNavCollapsed(false);
      }}
    >
      {categoryPages.map((categoryPage, index) => {
        let isActive;
        if (location?.state?.category !== undefined) {
          isActive = categoryPage.title === location.state.category;
        } else {
          isActive = categoryPage.title === defaultCategory;
        }

        return (
          <a
            key={index}
            className={classNames({
              "category-nav__item": true,
              active: isActive,
            })}
            href={categoryPage.url}
            onClick={(e) => {
              e.preventDefault();
              history.push(`/portfolio/${categoryPage.slug}/`, {
                category: categoryPage.title,
              });
            }}
          >
            {categoryPage.title}
          </a>
        );
      })}
    </div>
  );
}

CategoryNav.propTypes = {
  categoryPages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultCategory: PropTypes.string.isRequired,
};

export default CategoryNav;
