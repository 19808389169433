import React, { useRef } from "react";
import { coreDataType, scrollIntoView } from "../modules/utils";

import CTA from "./CTA";
import Carousel from "./Carousel";
import NavSpacer from "./NavSpacer";
import Page from "./Page";
import ProjectGrid from "./ProjectGrid";
import PropTypes from "prop-types";
import { useAppState } from "./App";

function HomePage({ pageData: { carouselItems, gridItems }, coreData }) {
  const headingRef = useRef();
  const { setNavCollapsed } = useAppState();

  const heroSimpleCategoryPage = coreData.simpleCategoryPages[0];

  return (
    <Page coreData={coreData}>
      <NavSpacer dark />
      <Carousel
        carouselItems={carouselItems}
        handleDownClick={() => {
          setNavCollapsed(true);
          scrollIntoView(headingRef.current);
        }}
      />
      <h1 className="grid-heading" ref={headingRef}>
        Featured work
      </h1>
      <ProjectGrid projects={gridItems} />
      <CTA
        url={heroSimpleCategoryPage.url}
        state={heroSimpleCategoryPage.title}
        text="View more work"
      />
    </Page>
  );
}

HomePage.propTypes = {
  pageData: PropTypes.shape({
    carouselItems: PropTypes.array.isRequired,
    gridItems: PropTypes.array.isRequired,
  }).isRequired,
  coreData: coreDataType.isRequired,
};

export default HomePage;
