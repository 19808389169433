import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useInView } from "react-intersection-observer";
import { useInterval } from "../modules/hooks";

function ProjectGridItem({
  url,
  imageUrl,
  title,
  brand,
  category,
  handleInView,
  active,
}) {
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      handleInView();
    }
  }, [inView]);

  return (
    <li
      ref={ref}
      className={classNames({
        "project-grid__item": true,
        "in-view": active,
      })}
    >
      <Link
        to={{
          pathname: url,
          state: { category: category },
        }}
        className="project-grid__link"
      >
        <div className="project-grid__image-wrapper">
          <img className="project-grid__image" src={imageUrl} />
        </div>
        <div className="project-grid__item-rollover">
          <div className="details-wrapper">
            <div className="project-grid__project-name-wrapper">
              <div className="project-grid__project-name">{title}</div>
            </div>
            <div className="project-grid__project-brand-wrapper">
              <div className="project-grid__project-brand">{brand}</div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}

ProjectGridItem.propTypes = {
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  mobileImageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  category: PropTypes.string,
  handleInView: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};

function ProjectGrid({ projects, category }) {
  const maxInViewIndexRef = useRef(0);
  const [maxEnabledIndex, setMaxEnabledIndex] = useState(null);
  const intervalStartTimeout = useRef();

  const { clearInterval, startInterval } = useInterval(() => {
    if (maxEnabledIndex === null) {
      setMaxEnabledIndex(0);
    } else {
      if (maxInViewIndexRef.current > maxEnabledIndex) {
        setMaxEnabledIndex(maxEnabledIndex + 1);
      }
    }
  }, 100);

  useEffect(() => {
    intervalStartTimeout.current = setTimeout(() => {
      startInterval();
    }, 200);

    return () => {
      clearTimeout(intervalStartTimeout.current);
      clearInterval();
    };
  }, []);

  return (
    <div className="project-grid">
      <div className="project-grid__inner">
        <ul className="project-grid__list">
          {projects.map((project, index) => {
            return (
              <ProjectGridItem
                key={index}
                index={index}
                url={project.url}
                imageUrl={project.heroImageUrl}
                mobileImageUrl={project.mobileHeroImageUrl}
                title={project.title}
                brand={project.brand}
                category={category}
                handleInView={() => {
                  if (index > maxInViewIndexRef.current) {
                    maxInViewIndexRef.current = index;
                  }
                }}
                active={maxEnabledIndex !== null && index <= maxEnabledIndex}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

ProjectGrid.propTypes = {
  projects: PropTypes.array.isRequired,
  category: PropTypes.string,
};

export default ProjectGrid;
