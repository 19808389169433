import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

function ProjectVideo({ videoId }) {
  const [showIframe, setShowIframe] = useState(false);
  const timeoutRef = useRef();

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      setShowIframe(true);
    }, 300);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="project-details__video-wrapper">
      {showIframe && (
        <iframe
          className="project-details__video"
          src={`//player.vimeo.com/video/${videoId}`}
          frameBorder="0"
          allowFullScreen
          playsInline
        />
      )}
    </div>
  );
}

ProjectVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default ProjectVideo;
