import React, { useRef } from "react";
import { coreDataType, scrollIntoView } from "../modules/utils";

import CTA from "./CTA";
import ClientLogos from "./ClientLogos";
import DownArrowSvg from "../../images/down-arrow.svg";
import HeadingAndText from "./HeadingAndText";
import NavSpacer from "./NavSpacer";
import Page from "./Page";
import PropTypes from "prop-types";

function AboutPage({
  pageData: { heading, text, clientGridItemsData },
  coreData,
}) {
  const clientLogosElRef = useRef();

  return (
    <Page coreData={coreData}>
      <NavSpacer />
      <HeadingAndText heading={heading}>
        <div
          className="text-header__text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <div
          className="text-header__down-button"
          onClick={() => {
            scrollIntoView(clientLogosElRef.current);
          }}
        >
          <DownArrowSvg />
        </div>
      </HeadingAndText>
      <div ref={clientLogosElRef}>
        <ClientLogos clients={clientGridItemsData} />
      </div>
      <CTA url={coreData.simpleContactPage.url} text="Get in touch" />
    </Page>
  );
}

AboutPage.propTypes = {
  coreData: coreDataType.isRequired,
  pageData: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    clientGridItemsData: PropTypes.array.isRequired,
  }).isRequired,
};

export default AboutPage;
