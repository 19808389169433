import React, { useEffect, useRef, useState } from "react";

import DownArrowSvg from "../../images/down-arrow.svg";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useInterval } from "../modules/hooks";
import { useWindowHeight } from "@react-hook/window-size";

function Pips({ carouselItems, activeIndex, handleClick }) {
  return (
    <div className="pips">
      {carouselItems.map((_, index) => {
        return (
          <button
            key={index}
            className={classNames({
              pip__button: true,
              active: activeIndex === index,
            })}
            onClick={() => {
              if (activeIndex !== index) {
                handleClick(index);
              }
            }}
          >
            <div className="pip__border" />
            <div className="pip__dot" />
          </button>
        );
      })}
    </div>
  );
}

Pips.propTypes = {
  carouselItems: PropTypes.array.isRequired,
  activeIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
};

function CarouselItem({
  url,
  title,
  brand,
  imageUrl,
  mobileImageUrl,
  isActive,
  isPrev,
  handleLoaded,
}) {
  return (
    <Link
      to={url}
      className={classNames({
        carousel__item: true,
        active: isActive,
        previous: isPrev,
      })}
    >
      <picture>
        <source srcSet={mobileImageUrl} media="(orientation: portrait)" />
        <source srcSet={imageUrl} media="(orientation: landscape)" />
        <img
          src={imageUrl}
          alt={title}
          className="carousel__image"
          onLoad={handleLoaded}
        />
      </picture>
      <div className="carousel__title-wrapper">
        <p className="carousel__title">{title}</p>
      </div>
      <div className="carousel__brand-wrapper">
        <p className="carousel__brand">{brand}</p>
      </div>
    </Link>
  );
}

const carouselItemFields = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  mobileImageUrl: PropTypes.string.isRequired,
};

CarouselItem.propTypes = {
  ...carouselItemFields,
  isActive: PropTypes.bool.isRequired,
  isPrev: PropTypes.bool.isRequired,
  handleLoaded: PropTypes.func.isRequired,
};

function Carousel({ carouselItems, handleDownClick }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [prevActiveIndex, setPrevActiveIndex] = useState(null);
  const imagesLoaded = useRef(0);
  const windowHeight = useWindowHeight();

  const { clearInterval, startInterval } = useInterval(() => {
    setPrevActiveIndex(activeIndex);
    let newActiveIndex = activeIndex + 1;
    if (newActiveIndex >= carouselItems.length) newActiveIndex = 0;
    setActiveIndex(newActiveIndex);
  }, 4000);

  useEffect(() => {
    return () => {
      clearInterval();
    };
  }, []);

  return (
    <div className="carousel" style={{ height: windowHeight - 70 }}>
      {carouselItems.map((carouselItem, index) => {
        return (
          <CarouselItem
            key={index}
            url={carouselItem.url}
            title={carouselItem.title}
            brand={carouselItem.brand}
            imageUrl={carouselItem.heroImageUrl}
            mobileImageUrl={carouselItem.mobileHeroImageUrl}
            isActive={index === activeIndex}
            isPrev={index === prevActiveIndex}
            handleLoaded={() => {
              imagesLoaded.current += 1;
              if (imagesLoaded.current === carouselItems.length) {
                startInterval();
              }
            }}
          />
        );
      })}
      <div className="carousel__actions">
        <button className="carousel__down-button" onClick={handleDownClick}>
          <DownArrowSvg width={20} fill="white" />
        </button>
        <Pips
          carouselItems={carouselItems}
          handleClick={(index) => {
            clearInterval();
            setPrevActiveIndex(activeIndex);
            setActiveIndex(index);
            startInterval();
          }}
          activeIndex={activeIndex}
        />
      </div>
    </div>
  );
}

Carousel.propTypes = {
  handleDownClick: PropTypes.func.isRequired,
  carouselItems: PropTypes.array.isRequired,
};

Carousel.defaultProps = {
  handleDownClick: () => {},
};

export default Carousel;
