import { useEffect, useRef, useState } from "react";

import camelcaseKeys from "camelcase-keys";
import { useLocation } from "react-router";

export function useIsScrollingDown() {
  // https://stackoverflow.com/questions/57088861/react-setstate-hook-from-scroll-event-listener

  const prevScrollRef = useRef(0);
  const scrollDownCounterRef = useRef(0);

  const [scrollingUp, setScrollingUp] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      const scrollingDown = prevScrollRef.current < currentScrollY;
      const overScrollBottom =
        currentScrollY >= document.body.scrollHeight - window.innerHeight;

      if (currentScrollY < 50) {
        if (!scrollingUp) {
          setScrollingUp(true);
        }
        return;
      }

      if (prevScrollRef.current > currentScrollY && currentScrollY > 0) {
        scrollDownCounterRef.current = 0;
        if (!scrollingUp && !overScrollBottom) {
          setScrollingUp(true);
        }
      } else if (scrollingDown) {
        scrollDownCounterRef.current += 1;
        if (scrollDownCounterRef.current > 5) {
          if (scrollingUp) {
            setScrollingUp(false);
          }
        }
      }
      prevScrollRef.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollingUp]);

  return scrollingUp;
}

export function useInterval(callback, delay) {
  const callbackRef = useRef();
  const intervalId = useRef();

  function startInterval() {
    intervalId.current = setInterval(() => {
      callbackRef.current();
    }, delay);
  }

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  return {
    clearInterval: () => {
      clearInterval(intervalId.current);
    },
    startInterval,
  };
}

export const IDLE = "IDLE";
export const CHANGED = "CHANGED";
export const TRANSITION_OUT = "TRANSITION_OUT";
export const TRANSITION_OUT_DONE = "TRANSITION_OUT_DONE";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const TRANSITION_IN = "TRANSITION_IN";
export const TRANSITION_IN_DONE = "TRANSITION_IN_DONE";
export const DONE = "DONE";

export const outStates = [TRANSITION_OUT, TRANSITION_OUT_DONE, LOADING, LOADED];
export const inStates = [TRANSITION_IN, TRANSITION_IN_DONE, DONE, IDLE];

export function usePageDataLoader(menuOpen) {
  const location = useLocation();
  const [allPageData, setAllPageData] = useState({});
  const [state, setState] = useState(LOADING);
  const [targetPathname, setTargetPathname] = useState(location.pathname);
  const [currPathname, setCurrPathname] = useState(window.location.pathname);
  const [prevPathname, setPrevPathname] = useState("");
  const [skipTransition, setSkipTransition] = useState(null);
  const timeoutRef = useRef(null);
  const [locationHistory, setLocationHistory] = useState([]);

  useEffect(() => {
    // If no transition is happening and the page changes, kick off the change
    if (state === IDLE && currPathname !== location.pathname) {
      // If we're skipping transitions (eg. a menu click) store that intention
      setTargetPathname(location.pathname);
      const skipTransition = !!location?.state?.skipTransition;
      setSkipTransition(skipTransition);
      setState(CHANGED);
    }
    setLocationHistory([...locationHistory, location]);
  }, [location.pathname]);

  useEffect(() => {
    // console.log(state);
    // If we are moving to a new url, either transition out,
    // or start loading if no transition is required
    if (state === CHANGED) {
      if (skipTransition && menuOpen) {
        setState(LOADING);
      } else {
        setState(TRANSITION_OUT);
      }
    }

    // Transition out for x00ms
    if (state === TRANSITION_OUT) {
      timeoutRef.current = setTimeout(() => {
        setState(TRANSITION_OUT_DONE);
      }, 500);
    }

    // Once the transition out is done, begin loading
    if (state === TRANSITION_OUT_DONE) {
      setState(LOADING);
    }

    // Get the data from the API or from the in-memory cache
    if (state === LOADING) {
      if (allPageData[targetPathname] === undefined) {
        fetch(`/api/v2/pages/with-path${targetPathname}`)
          .then((response) => response.json())
          .then((data) => {
            const pageApiData = camelcaseKeys(data, { deep: true });
            setAllPageData({
              ...allPageData,
              [targetPathname]: pageApiData,
            });
            setState(LOADED);
          })
          .catch((err) => {
            console.log(err);
            const pageApiData = {
              id: `ERROR_${targetPathname}`,
              class_name: "error",
              title: "Error",
              meta: { type: "ERROR" },
            };
            setAllPageData({
              ...allPageData,
              [targetPathname]: pageApiData,
            });
          });
      } else {
        setState(LOADED);
      }
    }

    // Once the data is loaded, either trandition in or skip to DONE
    // if no transition is required
    if (state === LOADED) {
      setPrevPathname(currPathname);
      setCurrPathname(targetPathname);

      const pageData = allPageData[targetPathname];
      const title = pageData?.meta?.seoTitle || pageData?.title;
      console.log(title);
      document.title = title;

      if (skipTransition && menuOpen) {
        setState(DONE);
      } else {
        setState(TRANSITION_IN);
      }
    }

    // Transition in for x00ms
    if (state === TRANSITION_IN) {
      timeoutRef.current = setTimeout(() => {
        setState(TRANSITION_IN_DONE);
      }, 500);
    }

    // Once the transition in is finished, move to done
    if (state === TRANSITION_IN_DONE) {
      setState(DONE);
    }

    // Re-check the location.pathname in case it's changed while the change was happening
    if (state === DONE) {
      if (currPathname !== location.pathname) {
        const skipTransition = !!location?.state?.skipTransition;
        setTargetPathname(location.pathname);
        setSkipTransition(skipTransition);
        setState(CHANGED);
      } else {
        setState(IDLE);
      }
    }
  }, [state]);

  return {
    allPageData,
    state,
    targetPathname,
    currPathname,
    skipTransition,
    prevPathname,
    locationHistory,
  };
}
