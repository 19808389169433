import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function NavSpacer({ dark }) {
  return <div className={classNames({ "nav-spacer": true, dark: dark })}></div>;
}

NavSpacer.propTypes = {
  dark: PropTypes.bool,
};

export default NavSpacer;
