import { inStates, outStates } from "../modules/hooks";

import Footer from "./Footer";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { coreDataType } from "../modules/utils";
import { useAppState } from "./App";

function Page({ coreData, children }) {
  const { state } = useAppState();
  const transitioningIn = inStates.includes(state);
  const transitioningOut = outStates.includes(state);

  return (
    <div
      className={classNames({
        page: true,
        "fade-transition-in": transitioningIn,
        "fade-transition-out": transitioningOut,
      })}
    >
      <div className="page-content">{children}</div>
      <Footer coreData={coreData} />
    </div>
  );
}

Page.propTypes = {
  coreData: coreDataType.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Page;
