import PropTypes from "prop-types";
import React from "react";

function ContactDetails({
  roleOne,
  nameOne,
  emailAddressOne,
  roleTwo,
  nameTwo,
  emailAddressTwo,
  addressCompany,
  addressStreet,
  addressCity,
  addressPostcode,
}) {
  return (
    <div className="contact">
      <div className="contact__inner">
        <ul className="contact__list">
          <li className="contact__item">
            <p>{roleOne}</p>
            <p>{nameOne}</p>
            <a href={`mailto:${emailAddressOne}`}>{emailAddressOne}</a>
          </li>
          <li className="contact__item">
            <p>{roleTwo}</p>
            <p>{nameTwo}</p>
            <a href={`mailto:${emailAddressTwo}`}>{emailAddressTwo}</a>
          </li>
        </ul>
        <p className="contact__address">
          {addressCompany}, {addressStreet}, {addressCity}, {addressPostcode}
        </p>
      </div>
    </div>
  );
}

ContactDetails.propTypes = {
  emailAddressOne: PropTypes.string.isRequired,
  emailAddressTwo: PropTypes.string.isRequired,
  nameOne: PropTypes.string.isRequired,
  nameTwo: PropTypes.string.isRequired,
  roleOne: PropTypes.string.isRequired,
  roleTwo: PropTypes.string.isRequired,
  addressCity: PropTypes.string.isRequired,
  addressCompany: PropTypes.string.isRequired,
  addressPostcode: PropTypes.string.isRequired,
  addressStreet: PropTypes.string.isRequired,
};

export default ContactDetails;
