import { coreDataType, simplePageType } from "../modules/utils";

import BackButtonSvg from "../../images/back-button.svg";
import CategoryNav from "./CategoryNav";
import NavSpacer from "./NavSpacer";
import Page from "./Page";
import ProjectGrid from "./ProjectGrid";
import ProjectVideo from "./ProjectVideo";
import PropTypes from "prop-types";
import React from "react";
import { useAppState } from "./App";
import { useHistory } from "react-router";

function ProjectDetailPage({
  pageData: {
    name,
    brand,
    director,
    description,
    coreCategoryData,
    relatedProjectsData,
    videoId,
    heroImageData,
  },
  coreData,
}) {
  const history = useHistory();
  const { locationHistory } = useAppState();

  return (
    <>
      <NavSpacer />
      <CategoryNav
        categoryPages={coreData.simpleCategoryPages}
        defaultCategory={coreCategoryData.title}
      />
      <Page coreData={coreData}>
        <div className="project-details">
          <button
            onClick={() => {
              if (locationHistory.length > 1) {
                const prevLocation =
                  locationHistory[locationHistory.length - 2];

                history.push(prevLocation.pathname, prevLocation.state);
              } else {
                history.push(`../${coreCategoryData.slug}/`);
              }
            }}
            className="back-button"
          >
            <BackButtonSvg width="44px" height="44px" />
          </button>
          <div className="project-details__info">
            <p className="project-details__name">{name}</p>
            <p className="project-details__brand">{brand}</p>
            <p className="project-details__director">Director - {director}</p>
          </div>
          <ProjectVideo
            name={name}
            brand={brand}
            director={director}
            heroImage={heroImageData}
            videoId={videoId}
          />
          <div
            className="project-details__description"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className="divider" />
        <h1 className="grid-heading">Related work</h1>
        {relatedProjectsData && <ProjectGrid projects={relatedProjectsData} />}
      </Page>
    </>
  );
}

ProjectDetailPage.propTypes = {
  pageData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    director: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    videoId: PropTypes.string.isRequired,
    heroImageData: PropTypes.string.isRequired,
    relatedProjectsData: PropTypes.array,
    coreCategoryData: simplePageType,
  }).isRequired,
  coreData: coreDataType.isRequired,
};

export default ProjectDetailPage;
