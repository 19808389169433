import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import {
  IDLE,
  LOADED,
  useIsScrollingDown,
  usePageDataLoader,
} from "../modules/hooks";
import React, { useContext, useEffect, useState } from "react";

import AboutPage from "./AboutPage";
import CategoryPage from "./CategoryPage";
import ContactPage from "./ContactPage";
import ErrorPage from "./ErrorPage";
import HomePage from "./HomePage";
import MainNav from "./MainNav";
import Menu from "./Menu";
import ProjectDetailPage from "./ProjectDetailPage";
import camelcaseKeys from "camelcase-keys";

const pageTypesMap = {
  "home.HomePage": HomePage,
  "contact.ContactPage": ContactPage,
  "about.AboutPage": AboutPage,
  "work.CategoryPage": CategoryPage,
  "work.ProjectDetailPage": ProjectDetailPage,
  ERROR: ErrorPage,
};

const coreData = camelcaseKeys(
  JSON.parse(document.getElementById("core_data").textContent),
  { deep: true }
);

if (coreData.sentryDsn) {
  try {
    Sentry.init({
      dsn: coreData.sentryDsn,
      release: coreData.version,
      environment: coreData.env,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.1,
      allowUrls: "giantowl",
    });
  } catch (error) {
    console.log("Error setting up Sentry");
  }
}

const AppStateContext = React.createContext();
export const useAppState = () => useContext(AppStateContext);

export default function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [categoryMenuOpen, setCategoryMenuOpen] = useState(false);
  const isScrollingDown = useIsScrollingDown();
  const [navCollapsed, setNavCollapsed] = useState(false);

  useEffect(() => {
    if (!isScrollingDown) {
      setNavCollapsed(true);
    } else {
      setNavCollapsed(false);
    }
  }, [isScrollingDown]);

  const {
    allPageData,
    targetPathname,
    currPathname,
    state,
    prevPathname,
    locationHistory,
  } = usePageDataLoader(menuOpen);

  useEffect(() => {
    if (state === LOADED) {
      window.scrollTo(0, 0);
    }
    if (state === IDLE) {
      setMenuOpen(false);
    }
  }, [state]);

  const pageData = allPageData[currPathname];

  if (!pageData) return null;

  const PageComponent = pageTypesMap[pageData.meta.type];

  return (
    <AppStateContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        categoryMenuOpen,
        setCategoryMenuOpen,
        navCollapsed,
        setNavCollapsed,
        state,
        currPathname,
        targetPathname,
        prevPathname,
        locationHistory,
      }}
    >
      <MainNav />
      <Menu coreData={coreData} />
      <PageComponent
        key={currPathname}
        pageData={pageData}
        coreData={coreData}
      />
    </AppStateContext.Provider>
  );
}
