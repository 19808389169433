import { useHistory, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";
import SocialLinks from "./SocialLinks";
import classNames from "classnames";
import { coreDataType } from "../modules/utils";
import { useAppState } from "./App";

function MenuItem({ title, url }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { setMenuOpen } = useAppState();
  return (
    <li className="menu__item">
      <a
        onClick={(e) => {
          e.preventDefault();
          if (pathname === url) {
            setMenuOpen(false);
          } else {
            history.push(url, { skipTransition: true });
          }
        }}
        href={url}
        className="menu__item"
      >
        {title}
      </a>
    </li>
  );
}

MenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

function Menu({
  coreData: {
    contactSettings: {
      addressCompany,
      addressStreet,
      addressCity,
      addressPostcode,
    },
    socialSettings: { twitterUrl, facebookUrl, instagramUrl, vimeoUrl },
    simpleCategoryPages,
    simpleAboutPage,
    simpleContactPage,
  },
}) {
  const { menuOpen } = useAppState();

  return (
    <div className={classNames({ menu: true, active: menuOpen })}>
      <ul className="menu__list">
        <MenuItem title="Portfolio" url={simpleCategoryPages[0].url} />
        <MenuItem title={simpleAboutPage.title} url={simpleAboutPage.url} />
        <MenuItem title={simpleContactPage.title} url={simpleContactPage.url} />
      </ul>
      <div className="menu__footer">
        <p className="menu__address">
          {addressCompany}, {addressStreet}, {addressCity}, {addressPostcode}
        </p>
        <SocialLinks
          twitterUrl={twitterUrl}
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          vimeoUrl={vimeoUrl}
        />
      </div>
    </div>
  );
}

Menu.propTypes = coreDataType.isRequired;

export default Menu;
