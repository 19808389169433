import React from "react";
import SocialLinks from "./SocialLinks";
import { coreDataType } from "../modules/utils";

function Footer({
  coreData: {
    contactSettings: {
      addressCity,
      addressCompany,
      addressPostcode,
      addressStreet,
      emailAddress,
      phoneNumber,
    },
    socialSettings: { twitterUrl, instagramUrl, facebookUrl, vimeoUrl },
  },
}) {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <ul className="footer__details">
          <li className="footer__address">
            {addressCompany}, <br />
            {addressStreet}, <br />
            {addressCity}, {addressPostcode}
          </li>
          <li className="footer__number">
            <a href={`tel:${phoneNumber}`}>Tel: {phoneNumber}</a>
          </li>
          <li className="footer__email">
            <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
          </li>
        </ul>
        <SocialLinks
          theme="pink-light"
          twitterUrl={twitterUrl}
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          vimeoUrl={vimeoUrl}
        />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  coreData: coreDataType.isRequired,
};

export default Footer;
