import "../styles/index.scss";

import App from "./components/App";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import smoothscroll from "smoothscroll-polyfill";

const browserHistory = createBrowserHistory();

history.scrollRestoration = "manual";

smoothscroll.polyfill();

ReactDOM.render(
  <Router history={browserHistory}>
    <App />
  </Router>,
  document.getElementById("root")
);
