import PropTypes from "prop-types";
import React from "react";

function HeadingAndText({ heading, children }) {
  return (
    <div className="text-header">
      <div className="text-header__inner">
        <h1 className="text-header__heading">{heading}</h1>
        {children}
      </div>
    </div>
  );
}

HeadingAndText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  heading: PropTypes.string.isRequired,
};

export default HeadingAndText;
