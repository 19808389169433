import React from "react";

function ErrorPage() {
  return (
    <div className="error-page">
      <h1 className="error__heading">Sorry</h1>
      <p className="error__text">An error has occured</p>
    </div>
  );
}

export default ErrorPage;
