import React from "react";
import classNames from "classnames";
import { useAppState } from "./App";

function BurgerButton() {
  const { menuOpen, setMenuOpen } = useAppState();

  return (
    <button
      className={classNames({ "burger-btn": true, active: menuOpen })}
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    >
      <div className="burger-btn__stripe burger-btn__stripe--top" />
      <div className="burger-btn__stripe burger-btn__stripe--middle" />
      <div className="burger-btn__stripe burger-btn__stripe--bottom" />
    </button>
  );
}

export default BurgerButton;
