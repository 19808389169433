import ContactDetails from "./ContactDetails";
import HeadingAndText from "./HeadingAndText";
import Map from "./Map";
import NavSpacer from "./NavSpacer";
import Page from "./Page";
import PropTypes from "prop-types";
import React from "react";
import SocialLinks from "./SocialLinks";
import { coreDataType } from "../modules/utils";

function ContactPage({
  pageData: {
    heading,
    emailAddressOne,
    emailAddressTwo,
    nameOne,
    nameTwo,
    roleOne,
    roleTwo,
  },
  coreData,
}) {
  return (
    <Page coreData={coreData}>
      <NavSpacer />
      <HeadingAndText heading={heading} />
      <ContactDetails
        emailAddressOne={emailAddressOne}
        emailAddressTwo={emailAddressTwo}
        nameOne={nameOne}
        nameTwo={nameTwo}
        roleOne={roleOne}
        roleTwo={roleTwo}
        addressCity={coreData.contactSettings.addressCity}
        addressCompany={coreData.contactSettings.addressCompany}
        addressPostcode={coreData.contactSettings.addressPostcode}
        addressStreet={coreData.contactSettings.addressStreet}
      />
      <div className="center">
        <SocialLinks
          twitterUrl={coreData.socialSettings.twitterUrl}
          facebookUrl={coreData.socialSettings.facebookUrl}
          instagramUrl={coreData.socialSettings.instagramUrl}
          vimeoUrl={coreData.socialSettings.vimeoUrl}
          theme="pink-dark"
        />
      </div>
      <Map lat={51.541814} lng={-0.0924605} />
    </Page>
  );
}

ContactPage.propTypes = {
  pageData: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    emailAddressOne: PropTypes.string.isRequired,
    emailAddressTwo: PropTypes.string.isRequired,
    nameOne: PropTypes.string.isRequired,
    nameTwo: PropTypes.string.isRequired,
    roleOne: PropTypes.string.isRequired,
    roleTwo: PropTypes.string.isRequired,
  }).isRequired,
  coreData: coreDataType.isRequired,
};

export default ContactPage;
