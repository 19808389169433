import PropTypes from "prop-types";
import React from "react";

function ClientLogo({ logo }) {
  return (
    <div className="client-logo__item">
      <div className="client-logo__image-wrapper">
        <img src={logo} className="client-logo__image" />
      </div>
    </div>
  );
}

ClientLogo.propTypes = {
  logo: PropTypes.string.isRequired,
};

function ClientLogos({ clients }) {
  return (
    <div className="client-logos">
      <div className="client-logos__inner">
        <h1 className="client-logos__header">Our clients</h1>
        <div className="client-logos__list">
          {clients.map((client, index) => {
            return <ClientLogo key={index} logo={client.logo} />;
          })}
        </div>
      </div>
    </div>
  );
}

ClientLogos.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({ logo: PropTypes.string.isRequired })
  ),
};

export default ClientLogos;
